<template>
    <div class="col-md-5 p-4">
        <p class="step">
            <span>Step 1</span>
            Register as a Reseller
        </p>
        <p class="step">
            <span>Step 2</span>
            Receive Web Account Registration Email
            <i class="fa fa-arrow-right"></i>
        </p>
        <p class="step">
            <span>Step 3</span>
            Complete Web Account Registration
            <i class="fa fa-arrow-right"></i>
        </p>
        <p class="step">
            <span>Step 4</span>
            Log In
        </p>

        <div id="instructions">
            <p>
                <span>Step 1 / Register as a Reseller</span>
                You can register via online application or download PDF application <router-link :to="{ name: 'BecomeReseller'}">here</router-link>.
            </p>
            <p>
                <span>Step 2 / Receive Web Account Registration Email</span>
                Once your application is approved, we will email you the Customer ID &amp; Password to log in. If you only receive the Customer ID, please follow step 3 to complete the process.
            </p>
            <p>
                <span>Step 3 / Complete Web Account Registration</span>
                Please complete the registration on <a href="/registration/">Web Account Registration</a> and create a password if you only receive the Customer ID.
            </p>
            <p>
                <span>Step 4 / Log In</span>
                You can log in <a href="/login">here</a> with the Customer ID &amp; Password. You can change the password in the Portal after logging in.
            </p>
            <p>
                If you want to check status of your application, please contact Credit Department: <a href="mailto:whcredit@malabs.com">whcredit@malabs.com</a>.<br>
            </p>
            <p>
                If you have trouble logging in, please email MIS Department: <a href="mailto:webmaster@malabs.com">webmaster@malabs.com</a>.
            </p>
        </div>
    </div>
</template>

<style scoped>
#instructions {
    clear: both;
    margin-top: 160px;
    font-size: 14px;
}
#instructions p {
    display: block;
    margin: 40px 0 10px 40px;
    color: #333;
    font-size: 16px;
}
#instructions p span {
    display: block;
    margin: 40px 0 10px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
}
p.step span {
    display: block;
    margin: 5px 0 10px;
    color: #275197;
    font-size: 17px;
}
p.step {
    float: left;
    position: relative;
    width: 152px;
    height: 130px;
    margin-left: 40px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    background: #E9EBF0;
    border-radius: 8px;
}
</style>