<template>
<breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
<div id="maincontent_container">
  <div class="pt-4">
    <ul class="nav nav-tabs">
      <li class="nav-link">
        <router-link :to="{ name: 'Login' }">Log in</router-link>
      </li>
      <li class="nav-link active">
        <router-link :to="{ name: 'RegisterAccountLink'}"
          >Web Account registration</router-link
        >
      </li>
      <li class="nav-link">
        <router-link :to="{ name: 'BecomeReseller'}"
          >Become Reseller</router-link
        >
      </li>
    </ul>
  </div>

  <div class="row">
    <div class="col-md-5 p-4">
      <h3 class="pb-4">Web Account Registration</h3>
      <span class="text-warning">All fields are required to be filled.</span>
      <br />
      
      <div v-if="message">
        <div v-for="msg in message" :key="msg">
          <div v-if ="success" class="alert alert-success alertsize" role="alert">
            {{ msg }}
          </div>
          <div v-else>
            <div class="alert alert-danger alertsize" role="alert">
              {{ msg }}        
            </div>
          </div>
        </div>

      </div>
      <div v-if="passwordNotMatch">
        <div class="alert alert-danger alertsize" role="alert">
          {{ passwordNotMatch }}        
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="col-3 mb-1">
          <input
            type="text"
            v-model="custno"
            class="form-control"
            required
            placeholder="Customer ID"
          />
          <br />
          <input
            type="text"
            v-model="email"
            class="form-control"
            required
            placeholder="Email on File"
          />
          <br />
          <input
            type="text"
            v-model="firstname"
            class="form-control"
            required
            placeholder="First Name"
          />
          <br />
          <input
            type="text"
            v-model="lastname"
            class="form-control"
            required
            placeholder="Last Name"
          />
          <br />
          <input
            type="password"
            v-model="password"
            class="form-control"
            required
            placeholder="Password"
          />
          <span class="text-warning"
            >Note: Password must be at least 6 characters long.</span
          >
          <br />
          <input
            type="password"
            v-model="confirmPassword"
            class="form-control"
            placeholder="Confirm Password"
          />
        </div>
        <br />
        <button v-if="loading == false" class="btn btn-primary submitbtn">Submit</button>
        <button v-if="loading == true" class="btn btn-primary btn-lg" disabled >
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Submit            
        </button>
        <!-- <button  class="btn btn-primary submitbtn">Submit</button> -->
      </form>
    </div>
    <login-instruction></login-instruction> 
  </div>
</div>
</template>

<script>
import { getAPI } from "../../utils/axios-api";
import { ref } from "vue";
import LoginInstruction from '../../components/login/LoginInstruction.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"

export default {
  name: "RegisterAccountLink",
  components:{LoginInstruction, BreadcrumbPage},
  setup() {
    const custno = ref("");
    const email = ref("");
    const firstname = ref("");
    const lastname = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    var message = ref([]);
    var success = ref(false)
    var passwordNotMatch = ref("");
    const loading = ref(false)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Web Account Registration", link: "RegisterAccountLink"})

    const handleSubmit = async () => {
      loading.value = true
      message.value = []
      passwordNotMatch.value = ""
      if (password.value != confirmPassword.value) {
        passwordNotMatch.value = "Passwords do NOT match! Please try again!";
        loading.value = false
        return 0
      }
      var url = "/account/register/";
      getAPI
        .post(url, {
          custno: custno.value,
          email: email.value,
          first_name: firstname.value,
          last_name: lastname.value,
          password: password.value,
        })
        .then((response) => {
          if(response.status == 201){
             message.value.push("Your account has been created! We have sent you a link to verify your newly registered Ma Labs web account, the link will be expired in 30 minutes.")
             success.value = true
             loading.value = false
          }
        })
        .catch((err) => {
          // error.value = err.response.data.non_field_errors;
          console.log(err);
          if(err.response.data.non_field_errors){
            message.value.push(err.response.data.non_field_errors[0])
          }
          if(err.response.data.password){
            message.value.push("Please enter valid password (password must be at least 6 characters long)!")
          }
          if(err.response.data.email){           
            message.value.push("Your email address is NOT valid!")                   
          }
          if(err.response.data.custno){
            message.value.push("Your custmer number is NOT valid!")
          }
          loading.value = false
        });
    };

    return {
      custno,
      email,
      firstname,
      lastname,
      password,
      confirmPassword,
      passwordNotMatch,
      handleSubmit,
      message,
      breadcrumb_links,
      success,
      loading
    };
  },
};
</script>

<style scoped>
input {
  display: block;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #999;
  width: 25em;
}
.submitbtn {
  padding: 10px 20px;
  border: 0;
  background: #0b6dff;
  color: white;
  margin-top: 20px;
  cursor: pointer;
}
.closebtn{
  float: right;
  border-radius: 20px;
}
.alertsize{
  width: 65%;
}
.text-warning {
  display: table;
  width: 438px;
  margin-left: 15px;
}
a, a:active, a:focus, a:visited {
    color: #555;
    text-decoration: none;
}
</style>
